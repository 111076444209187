import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Password from "antd/es/input/Password";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";
import toast, { Toaster } from "react-hot-toast";
import { Server } from "../../App";
import Googlelogin from "./Googlelogin";
import { Helmet } from "react-helmet";

const Register = (isModalOpen) => {
  const [loading, setloading] = useState(false);
  const [userType, setUserType] = useState("Candidate");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [CpasswordVisible, setCPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const toggleCPasswordVisibility = () => {
    setCPasswordVisible((prev) => !prev);
  };

  // console.log(userType);
  const handleCancel = () => {
    isModalOpen.setIsModalOpen(false);
  };

  const handleTypeToggle = (type) => {
    setUserType(type);
  };
  console.log(email, password, userType);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      let data = {
        email,
        password,
        name,
        role: userType,
      };
      if (email.length === 0) {
        toast.error("All Field are mandatory");
        setloading(false);
      } else if (password.length === 0) {
        toast.error("All Field are mandatory");
        setloading(false);
      } else if (password != confirmPassword) {
        toast.error("Password does not match");
        setloading(false);
      } else {
        setloading(true);
        const response = await axios.post(
          `${Server}/api/v1/auth/register`,
          data
        );
        console.log(response);
        if (response.data.message === "Please Confirm your email") {
          toast.success("Registered Successfully! Confirm your email");
          setTimeout(() => {
            setloading(false);
            isModalOpen.setModalType("login");
          }, 300);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setloading(false);
    }
  };
  const responseGoogle = async (response) => {
    try {
      // Send the Google response to your server for verification and user creation
      const googleResponse = await axios.post(
        `${Server}/api/v1/auth/googleLogin`,
        {
          tokenId: response.tokenId,
          role: userType,
        }
      );

      // Handle the response from your server
      console.log(googleResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Helmet>
        <meta
          name="Sign Up for Free | Join Skillstream Jobs | Top IT Opportunities in Canada"
          content="Sign up for free on SkillStream Jobs to access top IT opportunities in Canada. Join now to connect with leading employers and advance your tech career."
        />
      </Helmet>
      <Modal
        centered
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {confirmModal && (
          <ConfirmModal
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
          />
        )}
        <Toaster position="top-center" reverseOrder={false} />
        <div class="model">
          <div id="login-modal">
            <div class="login-form default-form">
              <div class="form-inner">
                <h3>Create a Account</h3>

                <form onSubmit={handleRegister}>
                  <div class="form-group">
                    <div class="btn-box row">
                      <div class="col-lg-6 col-md-12">
                        <button
                          type="button"
                          className={`theme-btn btn-style-four ${
                            userType === "Candidate" ? "active" : ""
                          }`}
                          onClick={() => handleTypeToggle("Candidate")}
                        >
                          <i className="la la-user"></i> Candidate
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <button
                          type="button"
                          className={`theme-btn btn-style-four ${
                            userType === "Employer" ? "active" : ""
                          }`}
                          onClick={() => handleTypeToggle("Employer")}
                        >
                          <i class="la la-briefcase"></i> Employer{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // required
                    />
                  </div>
                  {userType === "Candidate" ? (
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="Name"
                        placeholder="Enter Your Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // required
                      />
                    </div>
                  ) : (
                    <div class="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="Name"
                        placeholder="Enter Your Company Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // required
                      />
                    </div>
                  )}

                  <div className="form-group">
                    <label>Password</label>
                    <div className="password-input-group">
                      <input
                        id="password-field"
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={passwordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="password-input-group">
                      <input
                        id="password-field"
                        type={CpasswordVisible ? "text" : "password"}
                        name="Confirm Password"
                        placeholder=" Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={toggleCPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={CpasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <button
                      class="theme-btn btn-style-one "
                      type="submit"
                      name="Register"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Register"}
                    </button>
                  </div>
                </form>

                <div class="bottom-box">
                  <div class="text">
                    Already have an account?{" "}
                    <button
                      class="call-modal signup"
                      onClick={() => {
                        isModalOpen.setModalType("login");
                      }}
                    >
                      Signin
                    </button>
                  </div>
                  <div class="divider">
                    <span>or</span>
                  </div>
                  <div class="btn-box row">
                    <div class="col-lg-12 col-md-12">
                      <a href="#" class="theme-btn  facebook-btn">
                        <Googlelogin userType={userType} />
                      </a>
                    </div>
                    {/* <div class="col-lg-6 col-md-12">
                      <a href="#" class="theme-btn social-btn-two facebook-btn">
                        <i class="fab fa-facebook-f"></i> Log In via Facebook
                      </a>
                    </div>
                    <div class="col-lg-6 col-md-12">
                    
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
