import React, { useState, useEffect } from "react";
import { FaRobot, FaUser } from "react-icons/fa";
import "./QuestionPanel.css";
import { border } from "@chakra-ui/react";

const QuestionPanel = ({ question, onAnswer }) => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    setAnimationStarted(false);

    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(question);
      utterance.onend = () => setAnimationStarted(true);
      window.speechSynthesis.speak(utterance);
    }
  }, [question]);

  const answerAnimationStyle = {
    display: animationStarted ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
    animation: "fadeIn 1s ease-in-out",
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const frequencyBarStyle = (color, delay) => ({
    width: "10px",
    height: "50px",
    margin: "0 4px",
    borderRadius: "4px",
    backgroundColor: color,
    animation:
      "waveFrequency 1.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55)",
    animationDelay: `${delay}s`,
  });

  return (
    <div
      style={{
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        background: "linear-gradient(to bottom, #000000, #1e90ff)" ,
        color:"#fff",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FaRobot size={35} color="#007bff" />
        <h2 style={{fontSize:"22px",fontWeight: "500",}}>{question}</h2>
      </div>

      <div style={answerAnimationStyle}>
        <FaUser size={35} color="#007bff" />
        <div style={{ display: "flex", alignItems: "center" }}>
          {[...Array(15)].map((_, i) => (
            <div key={i} style={frequencyBarStyle(getRandomColor(), i * 0.1)} />
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <button onClick={onAnswer} style={nextButtonStyle}>
          Next Question
        </button>
      </div>
    </div>
  );
};

const nextButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#007bff",
  color: "#fff",
  borderRadius: "10px",
  border: "5px solid #fff",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

export default QuestionPanel;
