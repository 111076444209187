import React, { useState, useEffect } from "react";
import axios from "axios";
import { Server } from "App";
import { Spin } from "antd";
import toast, { Toaster } from "react-hot-toast";

const DemoModal = ({ isModalOpen, setIsModalOpen, selectedCandidate }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  });
  const [showType4, setShowType4] = useState(false);
  const [showType5, setShowType5] = useState(false);
  const [error, setError] = useState("");
  const [emailLoader, setEmailLoader] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(isModalOpen);
  };
  // console.log(selectedCandidate);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOption(""); // Reset the selected option when closing the modal
    setFormData({
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
    }); // Reset the form data
    setShowType4(false);
    setShowType5(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // console.log(convertObjectToArray());

  const handleAddMore = () => {
    if (!showType4) {
      setShowType4(true);
    } else if (!showType5) {
      setShowType5(true);
    }
  };
  const convertObjectToArray = (array) => {
    return Object.values(array).filter((value) => value !== "");
  };
  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    const instructions = convertObjectToArray(formData);
    // console.log(instructions);

    try {
      if (selectedOption === "with-instruction" && instructions.length < 3) {
        console.log(error);
        setError("Atleast three instructions required");
      } else {
        setEmailLoader(true);
        let payload = {
          ...selectedCandidate,
          instructions,
        };
        console.log(payload);
        console.log(
          `${Server}/api/v1/videoInterview/sendEmail/${selectedCandidate.userId}/${selectedCandidate.jobId}/${selectedCandidate.candidateId}`
        );
        const { data } = await axios.post(
          `${Server}/api/v1/videoInterview/sendEmail/${selectedCandidate.userId}/${selectedCandidate.jobId}/${selectedCandidate.candidateId}`,
          payload
        );
        console.log(data);
        toast.success("Email Invite Sent");
        setEmailLoader(false);
        setTimeout(() => {
          setIsModalOpen(false);
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in sending Interview Invite");
    }
    // console.log(formData);
  };

  if (!isModalOpen) {
    return (
      <div style={styles.appContainer}>
        <button style={styles.openButton} onClick={openModal}>
          Open Modal
        </button>
      </div>
    );
  }

  return (
    <div style={styles.modalOverlay}>
      <Toaster position="top-center" reverseOrder={false} />
      <div style={styles.modalContainer}>
        <button style={styles.closeButton} onClick={closeModal}>
          &times;
        </button>
        <h2>Select Option</h2>
        <select
          style={styles.dropdown}
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <option value="">-- Choose an Option --</option>
          <option value="with-instruction">Continue With Instruction</option>
          <option value="without-instruction">
            Continue Without Instruction
          </option>
        </select>

        {selectedOption === "with-instruction" && (
          <form style={styles.inputGroup} onSubmit={handleSubmit}>
            <label style={styles.label} htmlFor="question1">
              Instruction 1:
            </label>
            <input
              type="text"
              name="question1"
              id="question1"
              style={styles.inputField}
              value={formData.type1}
              onChange={handleInputChange}
              placeholder="Enter Instruction 1"
            />

            <label style={styles.label} htmlFor="question2">
              Instruction 2:
            </label>
            <input
              type="text"
              name="question2"
              id="question2"
              style={styles.inputField}
              value={formData.type2}
              onChange={handleInputChange}
              placeholder="Enter Instruction 2"
            />

            <label style={styles.label} htmlFor="question3">
              Instruction 3:
            </label>
            <input
              type="text"
              name="question3"
              id="question3"
              style={styles.inputField}
              value={formData.type3}
              onChange={handleInputChange}
              placeholder="Enter Instruction 3"
            />

            {showType4 && (
              <>
                <label style={styles.label} htmlFor="question4">
                  Instruction 4:
                </label>
                <input
                  type="text"
                  name="question4"
                  id="question4"
                  style={styles.inputField}
                  value={formData.type4}
                  onChange={handleInputChange}
                  placeholder="Enter Instruction 4"
                />
              </>
            )}
            {error?.length != 0 ? (
              <p style={{ color: "red" }}>{error}</p>
            ) : null}
            {showType5 && (
              <>
                <label style={styles.label} htmlFor="question5">
                  Instruction 5:
                </label>
                <input
                  type="text"
                  name="question5"
                  id="type5"
                  style={styles.inputField}
                  value={formData.type5}
                  onChange={handleInputChange}
                  placeholder="Enter Instruction 5"
                />
              </>
            )}

            {!showType5 && (
              <button
                type="button"
                style={styles.addButton}
                onClick={handleAddMore}
              >
                + Add More
              </button>
            )}

            <button type="submit" style={styles.submitButton}>
              {emailLoader ? <Spin /> : "Send Invite Without Instruction"}
            </button>
          </form>
        )}

        {selectedOption === "without-instruction" && (
          <div style={styles.skipAssessment}>
            <button style={styles.skipButton} onClick={handleSubmit}>
              {emailLoader ? <Spin /> : "Send Invite Without Instruction"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Inline CSS styles
const styles = {
  appContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  openButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative",
    animation: "slide-down 0.3s ease",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
  dropdown: {
    width: "100%",
    padding: "10px",
    marginTop: "20px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  inputGroup: {
    marginTop: "20px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  inputField: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  submitButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
  },
  addButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
  },
  skipAssessment: {
    marginTop: "20px",
    textAlign: "center",
  },
  skipButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default DemoModal;
