import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import VideoCam from "./VideoCam";
import QuestionPanel from "./QuestionPanel";
import FeedbackModal from "./FeedModal";
import InstructionsModal from "./InstructionModal";
import { FaRobot, FaClock, FaUser } from "react-icons/fa";
import backgroundImage from "../../assets/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner.jpg";

const PracticalInterview = () => {
  const location = useLocation();
  const { jobTitle, questions } = location.state || {};

  const [isSetup, setIsSetup] = useState(!questions);
  const [videoUrl, setVideoUrl] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isInterviewRunning, setIsInterviewRunning] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const mediaRecorderRef = useRef(null);
  const chunks = useRef([]);

  const startRecording = async () => {
    // Check if getDisplayMedia is supported for screen recording
    if (navigator.mediaDevices.getDisplayMedia) {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true, // Attempt to capture system audio
        });

        // Attempt to capture microphone audio if supported
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Combine streams if both are available
        const combinedStream = new MediaStream([
          ...screenStream.getVideoTracks(),
          ...screenStream.getAudioTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        handleRecording(combinedStream);
      } catch (err) {
        console.error("Screen recording is not supported or failed", err);
        fallbackToMicrophoneOnly();
      }
    } else {
      fallbackToMicrophoneOnly();
    }
  };

  const fallbackToMicrophoneOnly = async () => {
    try {
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      handleRecording(micStream);
    } catch (err) {
      console.error("Microphone recording failed:", err);
      alert("Your device does not support audio recording.");
    }
  };

  const handleRecording = (stream) => {
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunks.current, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
      chunks.current = [];
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
  };

  const downloadVideo = () => {
    const a = document.createElement("a");
    a.href = videoUrl;
    a.download = "interview_session.webm";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleAnswer = (answer) => {
    console.log("User answer:", answer);
    setLoading(true); // Start loader animation

    setTimeout(() => {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
      } else {
        // Automatically stop recording when the last question is finished
        stopRecording();
        setShowFeedback(true); // Show feedback after recording stops
      }
      setLoading(false); // Stop loader animation
    }, 2000); // Simulate loading time
  };

  const startInterview = () => {
    startRecording();
    setShowInstructions(true);
  };

  const confirmStartInterview = () => {
    setIsInterviewRunning(true);
    setShowInstructions(false);
  };

  const loaderStyle = {
    width: "50px",
    height: "50px",
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    left: "20%",
    height: "100vh",
    background: "linear-gradient(to bottom, #000000, #1e90ff)", // Black to Vibrant Blue
    backgroundSize: "cover",
    transition: "opacity 0.5s ease",
  };

  return (
    <div style={containerStyle}>
      {isSetup ? (
        <div>Please wait while we set up your interview...</div>
      ) : (
        <>
          <div
            style={{
              flex: 1,
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "8px",
            }}
          >
            <VideoCam />
          </div>
          <div
            style={{
              flex: 1,
              padding: "20px",
              marginLeft: "45px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "8px",
            }}
          >
            <div style={{ position: "relative", height: "100%" }}>
              {isInterviewRunning ? (
                <>
                  {loading && <div style={loaderStyle}></div>}
                  <QuestionPanel
                    question={questions[currentQuestion]}
                    onAnswer={handleAnswer}
                  />
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <button onClick={startInterview} style={buttonStyle}>
                    Start Interview
                  </button>
                </div>
              )}
            </div>
          </div>
          <InstructionsModal
            show={showInstructions}
            onClose={() => setShowInstructions(false)}
            onConfirm={confirmStartInterview}
          />
          <FeedbackModal
            show={showFeedback}
            onClose={() => setShowFeedback(false)}
            videoUrl={videoUrl}
            onDownload={downloadVideo}
          />
        </>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: "15px 30px",
  backgroundColor: "#2980b9",
  color: "#ecf0f1",
  border: "none",
  borderRadius: "12px",
  cursor: "pointer",
  fontSize: "18px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.6)",
  transition: "background-color 0.3s ease, transform 0.3s ease",
};

export default PracticalInterview;