import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { Select, Space } from "antd";
import { Server } from "App";
// import { LoaderIcon, Toaster, toast } from "react-hot-toast";

import axios from "axios";
const Mail = (modalOpen) => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const handleCancel = () => {
    modalOpen.setModalOpen(false);
  };
  // console.log(subject, message);
  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      let body = {
        from: modalOpen.fromEmail,
        emails: modalOpen.selectedEmails,
        subject: subject,
        message: message,
      };
      const { data } = await axios.post(
        `${Server}/api/v1/employer/send/email`,
        body
      );
      // console.log(data);
      toast.success("Email Sent");
      setTimeout(() => {
        modalOpen.setModalOpen(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error");
    }
  };
  return (
    <div>
      {" "}
      <Modal
        centered
        open={modalOpen.modalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {" "}
        <Toaster position="top-center" reverseOrder={false} />
        <div class="modal-body">
          <form role="form" class="form-horizontal" onSubmit={sendEmail}>
            <div class="form-group">
              <label class="col-lg-2 control-label">From</label>
              <div class="col-lg-12">
                <input
                  type="text"
                  placeholder=""
                  id="inputEmail1"
                  class="form-control"
                  value={modalOpen.fromEmail}
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-lg-2 control-label">To</label>
              <div class="col-lg-12">
                <input
                  type="text"
                  placeholder=""
                  id="inputEmail1"
                  class="form-control"
                  value={modalOpen.selectedEmails}
                />
              </div>
            </div>
            {/* <div class="form-group">
              <label class="col-lg-2 control-label">Cc / Bcc</label>
              <div class="col-lg-12">
                <input
                  type="text"
                  placeholder=""
                  id="cc"
                  class="form-control"
                />
              </div>
            </div> */}
            <div class="form-group">
              <label class="col-lg-2 control-label">Subject</label>
              <div class="col-lg-12">
                <input
                  type="text"
                  placeholder=""
                  id="inputPassword1"
                  class="form-control"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-lg-2 control-label">Message</label>
              <div class="col-lg-12">
                <textarea
                  rows="10"
                  cols="30"
                  class="form-control"
                  id=""
                  name=""
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                ></textarea>
              </div>
            </div>

            <div class="form-group">
              <div className="col-lg-offset-2 col-lg-12 mail-send">
                <span
                  className="btn green fileinput-button"
                  style={{
                    backgroundColor: "#28a745",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-plus fa-white"></i>
                  <span>Attachment</span>
                  <input
                    type="file"
                    name="files[]"
                    multiple
                    style={{ display: "none" }}
                  />
                </span>
                <div>
                  <button
                    className="btn btn-send"
                    type="submit"
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Mail;
