import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "../../Components/misc/Layouts";
import tw from "twin.macro";
import styled, { css } from "styled-components";
// import { css } from "styled-components/macro";
import Header from "../../Components/headers/light.js";
// import Footer from "../../Components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "../../Components/misc/Headings";
import { PrimaryButton } from "../../Components/misc/Buttons";

// Styled Components
const Container = styled.div`
  ${tw`flex flex-col min-h-screen bg-black`}
  background: linear-gradient(180deg, #000000 0%, #003366 100%);
`;

const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-white text-3xl md:text-4xl lg:text-5xl font-extrabold`;
const Posts = tw.div`mt-12 sm:-mr-8 flex flex-wrap justify-center`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-400 font-medium`}
      }
    `}
`;

const Post = styled.div`
  ${tw`cursor-pointer flex flex-col bg-blue-800 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out`}
  &:hover {
    ${tw`shadow-xl scale-105`}
  }
`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;

const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-blue-300 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-blue-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-500 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-white group-hover:text-blue-400 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center mt-8 mb-8`;
const LoadMoreButton = tw(PrimaryButton)`bg-blue-600 hover:bg-blue-700 text-white`;

// Main Component
export default ({
  headingText = "Blog Posts",
  posts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
      category: "Travel Tips",
      date: "April 21, 2020",
      title: "Safely Travel in Foreign Countries",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
      featured: true
    },
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost()
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && (
                      <Description>{post.description}</Description>
                    )}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>
                Load More
              </LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};

// Placeholder Post
const getPlaceholderPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Travel Guide",
  date: "April 19, 2020",
  title: "Visit the beautiful Alps in Switzerland",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com"
});
