import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import uniqid from "uniqid";
import axios from "axios";
import { Server } from "../../../App";
import { useNavigate } from "react-router-dom";
// import "./CVManager.css"; // Import your CSS file for styling

const CVManager = () => {
  const [selectedfile, setSelectedFile] = useState(() => {
    const storedFiles = localStorage.getItem("selectedFiles");
    return storedFiles ? JSON.parse(storedFiles) : [];
  });
  const [filesUploaded, setFilesUploaded] = useState(false);
  const navigate = useNavigate();
  let profileCompleted = localStorage.getItem("ProfileCompleted");
  let role = localStorage.getItem("Role");

  useEffect(() => {
    if (profileCompleted === "false") {
      console.log(typeof profileCompleted);
      if (role === "Candidate") {
        navigate("/my-profile");
      } else {
        navigate("/company-profile");
      }
    }
  }, []);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedFiles", JSON.stringify(selectedfile));
  }, [selectedfile]);

  const InputChange = (e) => {
    let documents = [];
    let unsupportedFiles = [];
  
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const extension = file.name.split('.').pop().toLowerCase();
      if (['doc', 'docx', 'pdf'].includes(extension)) {
        documents.push(file);
        let reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile((prevFiles) => {
            return [
              ...prevFiles,
              {
                id: uniqid(),
                file: file,
                filename: file.name,
                fileimage: reader.result,
                datetime: file.lastModifiedDate.toLocaleString("en-IN"),
                filesize: filesizes(file.size),
              },
            ];
          });
        };
        reader.readAsDataURL(file);
      } else {
        unsupportedFiles.push(file);
      }
    }

    if (unsupportedFiles.length > 0) {
      console.log("Unsupported files:", unsupportedFiles);
      alert("The following files are not supported: " + unsupportedFiles.map(file => file.name).join(", "));
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      setSelectedFile(result);
      setFilesUploaded(false); 
    }
  };

  console.log(selectedfile);
  let userId = localStorage.getItem("UserId");
  console.log(userId);

  const handleUpload = async (e) => {
    try {
      const formData = new FormData();
      selectedfile.forEach((fileData) => {
        formData.append("file", fileData.file); 
      });
      const { data } = await axios.post(
        `${Server}/api/v1/upload/resume/candidate/${userId}`,
        formData
      );
      console.log(data);
      if (data.message === "Successfully updated profile") {
        toast.success("Uploaded Successfully");
        setFilesUploaded(true); 
      }
    } catch (error) {
      console.log(error);
      toast.error("Error in Uploading Resume");
    }
  };

  const getFileTypeIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    const fileTypes = {
      doc: "fa fa-file-word-o",
      docx: "fa fa-file-word-o",
      pdf: "fa fa-file-pdf-o",
    };
    return fileTypes[extension] || "fa fa-file-o";
  };

  return (
    <div>
      <section class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <Toaster position="top-center" reverseOrder={false} />
            <h3>Cv Manager</h3>
            <div class="text">Ready to jump back in?</div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="cv-manager-widget ls-widget">
                <div class="widget-title">
                  <h4>Cv Manager</h4>
                </div>
                <div class="widget-content">
                  {!filesUploaded && (
                    <div class="uploading-resume">
                      <div class="uploadButton">
                        <input
                          class="uploadButton-input"
                          type="file"
                          name="attachments[]"
                          accept="image/*, application/pdf"
                          id="upload"
                          onChange={InputChange}
                          multiple
                        />
                        <label class="cv-uploadButton" for="upload">
                          <span class="title">Drop files here to upload</span>
                          <span class="text">
                            To upload file size is (Max 5Mb) and allowed file
                            types are (.doc, .docx, .pdf)
                          </span>
                          {selectedfile.length !== 0 && (
                            <button
                              class="theme-btn btn-style-one"
                              onClick={() => handleUpload()}
                            >
                              Upload Resume
                            </button>
                          )}
                        </label>
                        <span class="uploadButton-file-name"></span>
                      </div>
                    </div>
                  )}

                  <div class="files-outer">
                    {selectedfile?.map((data, index) => {
                      const {
                        id,
                        filename,
                        filetype,
                        fileimage,
                        datetime,
                        filesize,
                      } = data;

                      return (
                        <div class="file-edit-box" key={id}>
                          <span class="title">{filename}</span>
                          <span class="file-icon"><i className={getFileTypeIcon(filename)}></i></span>
                          <div class="edit-btns">
                            <button onClick={() => DeleteSelectFile(id)}>
                              <span class="la la-trash"></span>
                            </button>
                            <button>
                              <span class="la la-edit"></span>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="copyright-text">
        <p>© 2024 Skill Stream. All Right Reserved.</p>
      </div>
    </div>
  );
};

export default CVManager;
