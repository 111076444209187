import React, { useEffect, useState } from "react";
import img from "../../../assets/unnamedBlack.png";
import { useNavigate } from "react-router-dom";
import { Server } from "../../../App";
import axios from "axios";
import { Helmet } from "react-helmet";

const ProfileView = () => {
  const navigate = useNavigate();
  const [candidate, setCandidate] = useState([]);
  let profileCompleted = localStorage.getItem("ProfileCompleted");
  let role = localStorage.getItem("Role");
  useEffect(() => {
    if (profileCompleted === "false") {
      console.log(typeof profileCompleted);
      if (role === "Candidate") {
        navigate("/my-profile");
      } else {
        navigate("/company-profile");
      }
    }
  }, []);
  let userId = localStorage.getItem("UserId");
  console.log(candidate);

  const getallEmployers = async () => {
    try {
      const { data } = await axios.get(`${Server}/api/v1/candidate/${userId}`);
      setCandidate(data?.Candidate[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(relativeTime);
  useEffect(() => {
    getallEmployers();
  }, []);
  return (
    <section className="candidate-detail-section">
      <Helmet>
        <meta
          name="Tech Candidate Profile | Detailed IT Talent  | Skillstream Jobs Canada"
          content="Explore detailed tech candidate profiles on SkillStream Jobs Canada. Discover in-depth IT talent information to make informed hiring decisions with ease."
        />
      </Helmet>
      <div className="candidate-detail-outer">
        <div className="auto-container">
          <div className="row">
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              {/* Candidate block Five */}
              <div className="candidate-block-five at-v4">
                <div className="inner-box">
                  <div className="content">
                    <figure className="image">
                      <img src={candidate?.image} alt="" />
                    </figure>
                    <h4 className="name">
                      <a href="#">{candidate?.name} </a>
                    </h4>
                    <ul className="candidate-info at-sv5">
                      <li className="designation">{candidate?.position} </li>
                      <li>
                        <span className="icon fal fa-location-dot"></span>{" "}
                        {candidate?.city} ,{candidate?.country}
                      </li>
                      <li>
                        <span className="icon fal fa-circle-dollar"></span>{" "}
                        $&nbsp;
                        {candidate?.currentSalary}
                      </li>
                      {/*<li>
                        <span className="fas fa-star review-color"></span> 4.5
                        (8 Reviews)
                     </li>*/}
                    </ul>
                  </div>
                  {/*<button className="bookmark-btn">
                    <i className="flaticon-bookmark"></i>
                    </button>*/}
                </div>
                {/*     <ul className="post-tags at-sv5 mt20">
                  <li className="mb-2 mb-sm-0">
                    <a href="#">UX Designer</a>
                  </li>
                  <li className="mb-2 mb-sm-0">
                    <a href="#">Product Manager</a>
                  </li>
                  <li>
                    <a href="#">Developer</a>
                  </li>
                    </ul>*/}
                <div className="btn-box d-block d-sm-flex mt20">
                  {/* <a href="" className="theme-btn btn-style-one">Follow <i className="fal fa-long-arrow-right"></i></a> */}
                  <a
                    href={candidate.resume}
                    className="theme-btn btn-style-one"
                    target="_blank"
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                  >
                    View CV{" "}
                    <i className="fal fa-long-arrow-right text-white d-block ml15"></i>
                  </a>
                  <a
                    className="theme-btn btn-style-one"
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                    onClick={() => navigate("/my-profile")}
                  >
                    Edit Profile <i className="fal fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="job-detail">
                <h3 className="fz30 fw500 mb-4">About me</h3>
                <p>{candidate.description}</p>
                <hr className="opacity-100" />
                {/* Resume / Education */}
                <div className="resume-outer">
                  <div className="upper-title">
                    <h3 className="fz30 fw500 mb-4">Education</h3>
                  </div>
                  {/* Resume BLock */}
                  {candidate?.education?.map((e, idx) => (
                    <div className="resume-block at-sv5">
                      <div className="inner">
                        <span className="name">
                          {e.college.charAt(0).toUpperCase()}
                        </span>
                        <div className="title-box">
                          <div className="info-box">
                            <h3>{e.degree}</h3>
                            <span>{e.college}</span>
                          </div>
                          <div className="edit-box">
                            <span className="year">
                              {e.startYear}-{e.endYear}
                            </span>
                          </div>
                        </div>
                        {/* <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin a ipsum tellus. Interdum et malesuada
                          fames ac ante <br className="d-none d-lg-block" />{" "}
                          ipsum primis in faucibus.
                  </div>*/}
                      </div>
                    </div>
                  ))}

                  {/* Resume BLock */}
                </div>
                <hr className="opacity-100" />
                {/* Resume / Work & Experience */}
                <div className="resume-outer theme-blue">
                  <div className="upper-title">
                    <h3 className="fz30 fw500 mb-4">Work & Experience</h3>
                  </div>
                  {/* Resume BLock */}
                  {candidate?.work_experience?.map((exp, subIdx) => (
                    <div className="resume-block at-sv5" key={subIdx}>
                      <div className="inner">
                        <span className="name">S</span>
                        <div className="title-box">
                          <div className="info-box">
                            <h3>{exp.role}</h3>
                            <span>{exp.companyName}</span>
                          </div>
                          <div className="edit-box">
                            <span className="year">
                              {exp.startYear}-{exp.endYear}
                            </span>
                          </div>
                        </div>
                        {/*<div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin a ipsum tellus. Interdum et malesuada
                          fames ac ante
                          <br /> ipsum primis in faucibus.
                  </div>*/}
                      </div>
                    </div>
                  ))}

                  {/* Resume BLock */}
                </div>
                <hr className="opacity-100" />
                {/* Resume / Awards */}
                {candidate?.awards?.length === 0 ? null : (
                  <div className="resume-outer theme-yellow at-sv5">
                    <div className="upper-title">
                      <h3 className="fz30 fw500 mb-4">Honors & awards</h3>
                    </div>
                    {/* Resume BLock */}
                    <div className="resume-block at-sv5">
                      <div className="inner">
                        <span className="name"></span>
                        <div className="title-box">
                          <div className="info-box">
                            <h3>Perfect Attendance Programs</h3>
                            <p className="text fz15 mb-0">29 April 2023</p>
                            <span></span>
                          </div>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin a ipsum tellus. Interdum et malesuada
                          fames ac ante
                          <br /> ipsum primis in faucibus.
                        </div>
                      </div>
                    </div>
                    {/* Resume BLock */}
                    <div className="resume-block at-sv5">
                      <div className="inner pb-0 mb-0">
                        <span className="name"></span>
                        <div className="title-box">
                          <div className="info-box">
                            <h3>Top Performer Recognition</h3>
                            <p className="text fz15 mb-0">29 April 2023</p>
                            <span></span>
                          </div>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin a ipsum tellus. Interdum et malesuada
                          fames ac ante
                          <br /> ipsum primis in faucibus.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="opacity-100" />
                {/* Portfolio */}
                {/* <div className="portfolio-outer mb-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="upper-title">
                      <h3 className="fz30 fw500 mb-4">Projects</h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <figure className="image">
                      <a href="images/resource/portfolio-1.jpg" className="lightbox-image"><img src="images/resource/portfolio-1.jpg" alt="" /></a>
                      <span className="icon flaticon-plus"></span>
                    </figure>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <figure className="image">
                      <a href="images/resource/portfolio-2.jpg" className="lightbox-image"><img src="images/resource/portfolio-2.jpg" alt="" /></a>
                      <span className="icon flaticon-plus"></span>
                    </figure>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <figure className="image">
                      <a href="images/resource/portfolio-3.jpg" className="lightbox-image"><img src="images/resource/portfolio-3.jpg" alt="" /></a>
                      <span className="icon flaticon-plus"></span>
                    </figure>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <figure className="image">
                      <a href="images/resource/portfolio-4.jpg" className="lightbox-image"><img src="images/resource/portfolio-4.jpg" alt="" /></a>
                      <span className="icon flaticon-plus"></span>
                    </figure>
                  </div>
                </div>
              </div> */}

                {/* Video Box */}
                {/* <div className="video-outer">
                <h3 className="fz30 fw500 mb-4">Video</h3>
                <div className="video-box">
                  <figure className="image">
                    <a href="https://www.youtube.com/watch?v=Fvae8nxzVz4" className="play-now" data-fancybox="gallery" data-caption="">
                      <img src="images/resource/video-img.jpg" alt="" />
                      <i className="icon flaticon-play-button-3" aria-hidden="true"></i>
                    </a>
                  </figure>
                </div>
              </div> */}
              </div>
            </div>

            <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                <div className="sidebar-widget">
                  <h4 className="widget-title">More Information</h4>
                  <div className="widget-content">
                    <ul className="job-overview at-sv5">
                      <li>
                        <i className="icon flaticon-exercise"></i>
                        {/* <i className="fa-solid fa-circle-dollar"></i> */}
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Expected Salary</h5>
                          <span>$ &nbsp;{candidate?.expectedSalary}</span>
                        </div>
                      </li>
                      <li>
                        <i className="icon flaticon-title"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Experience</h5>
                          <span>{candidate?.experienceYears}</span>
                        </div>
                      </li>
                      {/* <li>
                        <i className="icon flaticon-exercise"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Qualification</h5>
                          <span>Associate Degree</span>
                        </div>
            </li>*/}
                      <li>
                        <i className="icon fal fa-language"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          {candidate?.language ? (
                            <>
                              <h5>Language:</h5>
                              <span>
                                {candidate?.language[0]},
                                {candidate?.language[1]}
                              </span>
                            </>
                          ) : null}
                        </div>
                      </li>
                      <li>
                        <i className="icon flaticon-email-3"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Email</h5>
                          <span>{candidate.email}</span>
                        </div>
                      </li>
                      <li>
                        <i className="icon flaticon-telephone-1"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Phone Number</h5>
                          <span>{candidate.phone}</span>
                        </div>
                      </li>
                      <li>
                        <i className="icon flaticon-pin-1"></i>
                        <div className="ml15" style={{ marginLeft: "15px" }}>
                          <h5>Location</h5>
                          <span>{candidate.city}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="text-center">
                    <div className="social-link-style1">
                      <a className="me-3" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="me-3" href="#">
                        <i class="fab fa-twitter-t"></i>
                      </a>
                      <a className="me-3" href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileView;
