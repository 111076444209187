import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { 
  FaUniversity, FaHome, FaUserTie, FaShoppingBag, 
  FaCalculator, FaHeadset, FaCalendarCheck, FaLaptop, 
  FaDatabase, FaPalette, FaBullhorn 
} from 'react-icons/fa';
import { SectionHeading } from "../../Components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import up from "../../assets/image.png"

// const Container = tw.div`relative bg-white py-10 px-5`;
const Container = styled.div`
  ${tw`relative`}

       background-image: url(${up});
  padding: 2rem;
  background-size: cover; /* Ensure the image covers the whole container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating */
`;

const SixColumnContainer = styled.div`
  ${tw`grid grid-cols-6 gap-4 max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Heading = tw(SectionHeading)`w-full text-blue-500`;

const Column = styled.div`
  ${tw`px-4 flex justify-center`}
`;

const Card = styled.div`
  ${tw`flex flex-col justify-between mx-auto max-w-sm items-center px-4 py-6 border-2 border-dashed border-blue-100 rounded-lg mt-6`}
  height: 100%;
  width: 100%;
  max-width: 300px;

  .icon {
    ${tw`text-blue-500 text-2xl mb-2`}
  }

  .textContainer {
    ${tw`text-sm font-medium text-blue-300 text-center`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const cards = [
  { name: 'Banking', icon: <FaUniversity /> },
  { name: 'Work From Home', icon: <FaHome /> },
  { name: 'HR', icon: <FaUserTie /> },
  { name: 'Sales', icon: <FaShoppingBag /> },
  { name: 'Accounting', icon: <FaCalculator /> },
  { name: 'Customer Support', icon: <FaHeadset /> },
  { name: 'Event Management', icon: <FaCalendarCheck /> },
  { name: 'IT', icon: <FaLaptop /> },
  { name: 'SQL', icon: <FaDatabase /> },
  { name: 'Oracle', icon: <FaDatabase /> },
  { name: 'Graphic Design', icon: <FaPalette /> },
  { name: 'Digital Marketing', icon: <FaBullhorn /> },
];

const ServicesSection = () => {
  return (
    <Container>
        <Heading>Popular<span tw="text-blue-500"> Categories</span></Heading>
      <SixColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <div className="icon">{card.icon}</div>
              <div className="textContainer">{card.name}</div>
            </Card>
          </Column>
        ))}
      </SixColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default ServicesSection;
