import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/businesswoman-discussing-with-colleagues-digital-tablet.jpg";

const poolQuestions = [
  "Explain a project you worked on and the technologies used.",
  "How do you approach debugging a complex issue?",
  "What are the key principles of object-oriented programming?",
  "Describe a challenging problem you faced and how you solved it.",
  "How do you stay updated with the latest technology trends?",
  "Explain a data project you have worked on and the tools you used.",
  "How do you handle missing or inconsistent data?",
  "Describe a time when your data analysis led to a business decision.",
  "What statistical methods are you familiar with?",
  "How do you validate the results of your models?",
  "Describe a product you managed and the process you followed.",
  "How do you prioritize features for a product?",
  "Explain how you handle disagreements with stakeholders.",
  "What metrics do you use to measure product success?",
  "How do you approach product roadmapping?",
  "Describe a design project you worked on and the tools you used.",
  "How do you conduct user research?",
  "What is your process for creating wireframes and prototypes?",
  "How do you handle feedback from users and stakeholders?",
  "What design principles do you follow?",
  "Explain a marketing campaign you developed and its impact.",
  "How do you analyze the effectiveness of your marketing strategies?",
  "What tools do you use for market research?",
  "Describe a time when you had to pivot your marketing strategy.",
  "How do you stay updated with marketing trends and tools?",
];

const getRandomQuestions = (numQuestions) => {
  const shuffled = poolQuestions.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numQuestions);
};

const SetupForm = () => {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [numQuestions, setNumQuestions] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({ jobTitle: "", numQuestions: "" });
  const [questionOptions, setQuestionOptions] = useState([]);

  useEffect(() => {
    setQuestionOptions(Array.from({ length: poolQuestions.length }, (_, i) => i + 1));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    const newErrors = { jobTitle: "", numQuestions: "" };

    if (!jobTitle) {
      newErrors.jobTitle = "Please enter a job title.";
      isValid = false;
    }

    if (numQuestions < 1 || numQuestions > poolQuestions.length) {
      newErrors.numQuestions = `Please enter a number between 1 and ${poolQuestions.length}.`;
      isValid = false;
    }

    if (isValid) {
      const questions = getRandomQuestions(numQuestions);
      navigate("/practical-interview", { state: { jobTitle, questions } });
    } else {
      setErrors(newErrors);
    }
  };

  const handleAIComplete = () => {
    // AI completion logic for job description can be added here
    // Placeholder code to show a simple alert
    alert("AI is generating job description...");
  };

  return (
    <div style={{ display: "flex", width: "100%", height: "100vh" }}>
      {/* Left Side - Image with Overlay */}
      <div
        style={{
          flex: "1",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {/* Overlay */}
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <h2
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Welcome to the Practical Interview Assessment
          </h2>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#fff",
            }}
          >
            Prepare for your interviews with a realistic and structured
            approach. Customize your assessment based on job roles, and evaluate
            candidates effectively.
          </p>
        </div>
      </div>

      {/* Right Side - Content and Form */}
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          boxSizing: "border-box",
          backgroundColor: "#f5f5f5",
        }}
      >
        {!showForm ? (
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "28px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Practical Interview Assessment
            </h2>
            <p
              style={{
                fontSize: "18px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              Customize your interview process with tailored questions and
              timing options.
            </p>
            <ul
              style={{
                fontSize: "18px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              <li>Evaluate candidates with real-world scenarios.</li>
              <li>Customize questions based on job roles.</li>
              <li>Manage interview timing and question flow.</li>
              <li>Gather insights and make data-driven decisions.</li>
            </ul>
            <button
              onClick={() => setShowForm(true)}
              style={{
                backgroundColor: "#1d4ed8",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Start Interview
            </button>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              maxWidth: "600px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Interview Setup
            </h2>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Job Title
              </label>
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => {
                  setJobTitle(e.target.value);
                  setErrors((prev) => ({ ...prev, jobTitle: "" }));
                }}
                placeholder="Enter job title"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: `1px solid ${errors.jobTitle ? "#e74c3c" : "#ccc"}`,
                }}
              />
              {errors.jobTitle && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.jobTitle}
                </p>
              )}
            </div>

           

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Job Description
              </label>
              <div style={{display:"flex",justifyContent:"flex-end"}}>
              <button
              onClick={handleAIComplete}
              type="button"
              style={{
                backgroundColor: "#2980b9",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                fontSize: "12px",
                fontWeight: "bold",
                marginBottom: "15px",
              }}
            >
              Complete with AI
            </button>
            </div>
              <textarea
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                placeholder="Enter job description"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  height: "100px",
                  resize: "vertical",
                }}
              />
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Number of Questions
              </label>
              <input
                type="number"
                min="1"
                max={poolQuestions.length}
                value={numQuestions}
                onChange={(e) => {
                  setNumQuestions(parseInt(e.target.value, 10));
                  setErrors((prev) => ({ ...prev, numQuestions: "" }));
                }}
                placeholder={`1-${poolQuestions.length}`}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: `1px solid ${errors.numQuestions ? "#e74c3c" : "#ccc"}`,
                }}
              />
              {errors.numQuestions && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.numQuestions}
                </p>
              )}
            </div>

            <button
              type="submit"
              style={{
                backgroundColor: "#1d4ed8",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Generate Questions
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetupForm;
