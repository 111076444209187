import images from './images';

const features = [
  {
    title: "Advanced Search",
    description: "Find the perfect candidates quickly with advanced search filters.",
    icon: "path/to/icon1.png" // Replace with actual icon path
  },
  {
    title: "Candidate Profiles",
    description: "Access detailed profiles to make informed hiring decisions.",
    icon: "path/to/icon2.png" // Replace with actual icon path
  },
  {
    title: "Real-Time Analytics",
    description: "Get insights and reports on your job postings and candidate engagement.",
    icon: "path/to/icon3.png" // Replace with actual icon path
  },
  {
    title: "Automated Job Matching",
    description: "Automatically match candidates to your job postings based on their profiles.",
    icon: "path/to/icon4.png" // Replace with actual icon path
  },
  {
    title: "Customizable Job Listings",
    description: "Create job listings that highlight your company’s unique attributes.",
    icon: "path/to/icon5.png" // Replace with actual icon path
  },
  {
    title: "Integrated Communication Tools",
    description: "Communicate with candidates directly through our platform with integrated messaging tools.",
    icon: "path/to/icon6.png" // Replace with actual icon path
  }
];

const benefits = [
  {
    title: "Increased Visibility",
    description: "Your job postings will reach a broader audience, increasing the chances of finding the right candidate.",
    icon: "path/to/icon7.png" // Replace with actual icon path
  },
  {
    title: "Cost-Effective",
    description: "Benefit from our competitive pricing and get more value for your investment.",
    icon: "path/to/icon8.png" // Replace with actual icon path
  },
  {
    title: "Support & Assistance",
    description: "Receive dedicated support to help you make the most out of our job board.",
    icon: "path/to/icon9.png" // Replace with actual icon path
  },
  {
    title: "Easy Integration",
    description: "Seamlessly integrate with your existing HR systems and tools.",
    icon: "path/to/icon10.png" // Replace with actual icon path
  },
  {
    title: "High-Quality Candidates",
    description: "Attract highly qualified candidates who are actively seeking new opportunities.",
    icon: "path/to/icon11.png" // Replace with actual icon path
  },
  {
    title: "Enhanced Reporting",
    description: "Access comprehensive reports and analytics to track the effectiveness of your job postings.",
    icon: "path/to/icon12.png" // Replace with actual icon path
  }
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { features, benefits, awards };
